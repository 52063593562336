//
//
//
//
//
//
//
//
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
export default {
  components: {},
  updated: function updated() {
    new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
  }
};